import { request } from '@/utils/Tool'

export function getUserInfoFull(data) {
    return new Promise(function(resolve, reject) {
        request('CSKY_getPersonInfo', data).then((result) => {
            // 增加数据处理通用方法
            if (result) {
                resolve(result)
            } else {
                reject(null)
            }
        })
    })
}

export function getUserByUsername(regname) {
    const data = {
        username: regname
    }
    return new Promise(function(resolve, reject) {
        request('CSKY_getUserByUsername', data).then((result) => {
            if (result) {
                resolve(result)
            } else {
                reject(null)
            }
        })
    })
}
export async function modifyBasicInfo(data) {
    const result = await request('CSKY_modifyBasicInfo', data)
    return result
}
export async function modifyPerson(data) {
    const result = await request('CSKY_modifyPersonInfo', data)
    return result
}
export async function modifyCompany(data) {
    const result = await request('CSKY_modifyCompanyInfo', data)
    return result
}

export async function personRegister(data) {
    const result = await request('CSKY_register', data)
    return result
}

export function modifyFilePhoto(data) {
    return new Promise(function(resolve, reject) {
        request('CSKY_modifyPersonInfo', data).then((result) => {
            if (result) {
                if (result.resultstate == 1) {
                    resolve(result)
                } else {
                    reject(result.resultdesc)
                }
            } else {
                reject(null)
            }
        })
    })
}

export async function checkPassword(data) {
    const result = await request('CSKY_checkPassword', data)
    return result
}

export async function modifyPassword(data) {
    const result = await request('CSKY_modifyPassword', data)
    return result
}

// pc
export function forgotPassword(data) {
    return new Promise(function(resolve, reject) {
        request('CSKY_modifyPasswordByNew', data).then((result) => {
            if (result) {
                resolve(result)
            } else {
                reject(null)
            }
        })
    })
}

export async function Policecheck(data) {
    const result = await request('Policecheck', data)
    return result
}

export async function checkIsSignup(data) {
    const result = await request('CSKY_checkIsSignup', data)
    return result
}

export async function getCompanyInfo(data) {
    const result = await request('CSKY_getCompanyInfoByName', data)
    return result
}

